import React, { useState } from "react";
import images from "../../images/images";

const Itinerary = () => {
  const [loadingStates, setLoadingStates] = useState([
    [true, true, true, true, true], // Day 1: 5 images
    [true, true, true, true], // Day 2: 4 images
    [true, true, true, true], // Day 3: 4 images
  ]);

  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const handleImageLoad = (dayIndex, imageIndex) => {
    setLoadingStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[dayIndex] = [...newStates[dayIndex]];
      newStates[dayIndex][imageIndex] = false; // Set image as loaded
      return newStates;
    });
  };

  const imageUrls = [
    [
      images.day101,
      images.day102,
      images.day103,
      images.day104,
      images.day105,
      images.day106,
    ],
    [
      images.day201,
      images.day202,
      images.day203,
      images.day204,
      images.day205,
      images.day206,
    ],
    [
      images.day301,
      images.day302,
      images.day303,
      images.day304,
      images.day305,
      images.day306,
    ],
  ];

  const days = [
    "Day 1 – Arrival at Ooty (Services: Stay & Car)",
    "Day 2 – Ooty-Pykara-sightseeing (Services: Stay, Breakfast & Car)",
    "Day 3 – Departure Ooty to Coonoor (Services: Breakfast & Car)",
  ];

  const day1Buttons = [
    "Botanical Garden",
    "Rose Gardens",
    "Doddabetta Peak",
    "Tea Factory",
    "Overnight Hotel Stay",
  ];

  const day2Buttons = [
    "Pine forest",
    "6th mile",
    "Shooting spot",
    "Tree Park",
    "Pykara Falls & Lake",
    "Ooty Lake & Boat Club",
    "Overnight Hotel Stay",
  ];

  const day3Buttons = [
    "Wax Museum",
    "Ketti Valley View",
    "Mrc Regiment",
    "Dolphin Nose",
    "Sim's Park",
    "Lamb's Rock",
  ];

  const buttonsForDays = [day1Buttons, day2Buttons, day3Buttons];

  return (
    <div className="container pt-3 px-4 pb-80">
      <div className="row">
        <div className="col">
          <h2 className="py-4">
            Ooty 2N3D Package <span className="text-warning">Itinerary</span>
          </h2>
        </div>
      </div>
      <div className="accordion">
        {days.map((dayTitle, dayIndex) => (
          <div key={dayIndex} className="accordion-item border-none mt-5">
            <h2 className="accordion-header">
              <button
                className="accordion-button border-none"
                type="button"
                onClick={() => toggleAccordion(dayIndex + 1)}
                aria-expanded={openItem === dayIndex + 1}
              >
                <h5>{dayTitle}</h5>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === dayIndex + 1 ? "show" : ""
              }`}
            >
              <div className="accordion-body">
                <div className="button-list py-3 d-none d-md-block">
                  {buttonsForDays[dayIndex].map((label, index) => (
                    <div key={index} className="button-custom">
                      <button className="custom-btn">{label}</button>
                    </div>
                  ))}
                </div>

                <p className="py-4">
                  {dayIndex === 0 && (
                    <>
                      Upon Reaching Ooty, our team will greet you and transfer
                      you to the hotel. (Check in time will be 12.00noon and
                      Early check-in will be subject to availability and extra
                      charge applies). After refreshment, visit the Botanical
                      Gardens, Rose Gardens, Doddabetta peak, Tea Factory. Stay
                      overnight at a hotel. 
                      {/* {dayTitle.toLowerCase()}. */}
                    </>
                  )}
                  {dayIndex === 1 && (
                    <>
              Today after breakfast at the hotel, visit, explore the Drive to Pykara sightseeing, Pine forest, 6th mile,  Shooting spot,
Tree park, Pykara lake, Pykara falls, Ooty Lake & Boat Club.. 
Stay overnight at a hotel.{" "}
                      {/* {dayTitle.toLowerCase()}. */}
                    </>
                  )}
                  {dayIndex === 2 && (
                    <>
                      After breakfast check out hotel Drive to Ooty Coonoor sightseeing Visit Wax Museum, Ketti Valley View, 
Mrc Regiment (military Camp, Outside View Only), Dolphin Nose, Sim's Park and Lamb's Rock.
Later as per the departure timing drop at Ooty Bus stop.{" "}
                      {/* npm{dayTitle.toLowerCase()}. */}
                    </>
                  )}
                </p>

                <div className="row mt-3">
                  {imageUrls[dayIndex].map((url, imageIndex) => (
                    <div key={imageIndex} className="col-6 col-md-2 mb-3">
                      {loadingStates[dayIndex][imageIndex] && (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      <img
                        src={url}
                        alt={`img${imageIndex + 1}`}
                        className="img-fluid"
                        onLoad={() => handleImageLoad(dayIndex, imageIndex)}
                        style={{
                          display: loadingStates[dayIndex][imageIndex]
                            ? "none"
                            : "block",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Itinerary;
