import React, { useState } from "react";

const Frequently = () => {
  const [openItem, setOpenItem] = useState(null);

  const toggleAccordion = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  return (
    <div className="py-80 my-100 " style={{ backgroundColor: "#EEFEFF" }}>
      <div className="row text-center">
        <div className="col-md-12 col-lg-12 col-sm-12 ">
          <h2> Frequently Asked Questions </h2>
          <p className="text-center py-2 px-3">
            {" "}
            Below you will find answers to some of the most commonly asked
            questions about our services. If you have any other inquiries, feel
            free to reach out to us.
          </p>
        </div>
      </div>
      <div className="container my-4">
        <div className="accordion">
          {/* Accordion Item 1 */}
          <div className="accordion-item border-none">
            <h2 className="accordion-header ">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(1)}
                aria-expanded={openItem === 1}
              >
                <h3>Why Ooty?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 1 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                  Ooty is a must-visit for its breath taking landscapes,
                  including lush tea gardens and panoramic views from Doddabetta
                  Peak. The charming colonial architecture and the scenic
                  Nilgiri Mountain Railway offer unique experiences. Its cool
                  climate and vibrant botanical gardens add to its irresistible
                  appeal.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 2 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(2)}
                aria-expanded={openItem === 2}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3>What is the best time to visit Ooty?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 2 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                  The best time to visit Ooty is from April to June and from
                  September to November. During these months, the weather is
                  pleasant and ideal for sightseeing.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 3 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(3)}
                aria-expanded={openItem === 3}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3> Do you offer complementary dinner ? </h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 3 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="">
                Yes, our tour packages to Ooty are designed to provide a comfortable and enjoyable experience. Depending on the package you choose, breakfast may be included at your accommodation. Please check the details of each package for specific inclusions.
                </p>
              </div>
            </div>
          </div>

          {/* Accordion Item 4 */}
          <div className="accordion-item border-none mt-5">
            <h2 className="accordion-header border-Frequently">
              <button
                className="accordion-button border-Frequently"
                type="button"
                onClick={() => toggleAccordion(4)}
                aria-expanded={openItem === 4}
                style={{ backgroundColor: "#EEFEFF" }}
              >
                <h3>Bangalore pickup is available?</h3>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${
                openItem === 4 ? "show" : ""
              }`}
            >
              <div className="accordion-body border-Frequently">
                <p className="py-1">
                  If you're inquiring about general pickup services in
                  Bangalore, many delivery and logistics companies offer pickup
                  services in the city. Companies like Shadowfax, Dunzo, and
                  others often provide pickup options for various needs,
                  including e-commerce, personal deliveries, and more. If you
                  need information about a specific service, please let me know!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frequently;
