import React from "react";
import Header from "../../CommonCompounds/Header/Header.jsx";
import OotyPackageCarousel from "../../CommonCompounds/OotyPackageCarousel/OotyPackageCarousel.jsx"
import Index from "../../Components/HomePage/HomePage.jsx";
import Footer from "../../CommonCompounds/Footer/Footer.jsx";
import Frequently from "../../CommonCompounds/Frequently/Frequently.jsx"
import { Carousel } from "bootstrap";
import CarouselPackage from "../../CommonCompounds/CarouselPackage/CarouselPackage.jsx"
import TestimonialCarousel from "../../CommonCompounds/TestimonialCarousel/TestimonialCarousel.jsx"

function HomePage(){
    return(
        <>
        <Header />
        <OotyPackageCarousel />
        <Index />
        <TestimonialCarousel />
        <Frequently />
       
        <CarouselPackage />
        <Footer />
        </>
    )
}

export default HomePage;