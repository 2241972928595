import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import images from '../../images/images';// Assuming this is where your images are stored

const OotyPackageCarousel = () => {
  return (
    <Carousel indicators={true} controls={true}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={images.banner1} // First image
          alt="Ooty Package"
          style={{ height: '650px', objectFit: 'cover' }}
        />
        <Carousel.Caption className="text-center ">
          <h1 className="text-white font-weight-bold ">
            OOTY 2NIGHTS 3DAYS COUPLE PACKAGE
          </h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={images.banner2} // Second image
          alt="Ooty Package"
          style={{ height: '650px', objectFit: 'cover' }}
        />
        <Carousel.Caption className="text-center">
          <h1 className="text-white font-weight-bold">
            OOTY 2NIGHTS 3DAYS COUPLE PACKAGE
          </h1>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src={images.banner3} // Third image
          alt="Ooty Package"
          style={{ height: '650px', objectFit: 'cover' }}
        />
        <Carousel.Caption className="text-center">
          <h1 className="text-white font-weight-bold">
            OOTY 2NIGHTS 3DAYS COUPLE PACKAGE
          </h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default OotyPackageCarousel;
